<template>
  <b-container v-touch:swipe.right="swipeRight">
    <b-row>
      <b-col cols="1"/>
        <b-col>
        <h2>App Funktionen</h2>
        </b-col>
    </b-row>
    <b-row>
      <b-col cols="1"/>
        <b-col cols="10">
        <img src="@/assets/illustrations/bikerwhiteshirt.svg" class="illustration" alt="illustration" style="width: 100%; height: 100%; margin-top: 1.5rem; margin-bottom: 1.25rem;"/>
        </b-col>
      <b-col cols="1"/>
    </b-row>
    <br/>
    <b-row>
      <b-col cols="1"/>
        <b-col cols="10">
          <p>Im Menü oben links in der Ecke findest du eine Übersicht über dein Zeitbudget, den Bewertungsverlauf, dein Profil und deinen ökologischen Fußabdruck.</p>
          <p>Du kannst loslegen. Bitte fahre vorsichtig.</p>
        </b-col> 
      <b-col cols="1"/>
    </b-row>
    <b-row class="fixed-bottom pageIndicatorPos">
      <b-col cols="2"/>
      <b-col cols="8">
        <PageIndicator route="appTutorial" :max="6" :pos="6"/>
      </b-col>
      <b-col cols="2"/>
    </b-row>
    <b-row>
      <NextButton text="Fertig" next @click="$router.push('/modi')" />
    </b-row>
  </b-container>
</template>

<script>
import NextButton from '../components/NextButton';
import PageIndicator from '../components/PageIndicator';
  export default {
    name: 'AppTutorial6',
    components: {
      PageIndicator,
      NextButton,
    },
    methods: {
      swipeRight() {
        this.$router.push('/appTutorial/5')
      }
    }
  }
</script>

<style scoped>
.illustration {
  pointer-events: none;
}
</style>